




























import { Component, Vue } from "vue-property-decorator";
import { giveAccessToPrivateRoutes } from "@/app/app.router";
import { Form } from "element-ui";
import { Validator } from "@/app/shared/validators/form-rules.types";
import { AxiosError } from "axios";

import Login from "@/app/shared/models/login.model";

// import { ValidateFieldCallback } from "element-ui/types/form";
// import EmailRegExp from "@/app/shared/validators/email.regexp";

// const validateEmail = (
//   rule: { [key: string]: Rule },
//   value: string | null,
//   callback: ValidateFieldCallback
// ): void => {
//   if (!value) {
//     callback("Email is required");
//     return;
//   }
//   const valid = EmailRegExp.test(value);
//   if (valid) {
//     callback("");
//     return;
//   }
//   callback("Your e-mail field contains invalid value");
// };

@Component
export default class LoginView extends Vue {
  $refs!: {
    "login-form": Form;
  };

  private loginForm: Login = {
    login: "",
    password: "",
  };

  private rules: Validator<Login> = {
    login: [
      {
        required: true,
        message: "Please input login",
        trigger: ["change", "blur"],
      },
      // {
      //   validator: validateEmail,
      //   trigger: ["change", "blur"],
      // },
    ],
    password: [
      {
        required: true,
        message: "Please input password",
        trigger: ["change", "blur"],
      },
    ],
  };

  private async login(): Promise<void> {
    try {
      await this.$refs["login-form"].validate();
    } catch (error) {
      return;
    }

    try {
      await this.$store.dispatch("configModule/getToken", this.loginForm);
      this.$store.dispatch("configModule/getUserRoles");
      giveAccessToPrivateRoutes(this.$store);
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 401) {
        this.$message({
          message: "Incorrect login or password",
          type: "error",
        });
      } else console.error(error);
    }
  }
}
